import React, { useEffect, useRef } from 'react'
// import { Helmet } from "react-helmet"
import "../css/normalize.css"
import "../css/reset.css"
import "../css/custom.css"
import "../css/fonts.css"
import previewImage from "../image/home-menu.png"

import Typing from 'react-typing-animation'

import Application from "../components/Application"
import Header from "../components/header"
import AboutBody from "../components/aboutBody"
import ContactBody from "../components/contactBody"
import Footer from "../components/footer"
import GallerySlide from "../components/gallerySlide"
// import About from './about'


const textShowSpeed = 8;
const textDeleteSpeed = 1;
const textInitTime = 1500;
const textPageTime = 2000;

const AnimatedTypingComponent = () => (
  <div id="menugroup" className="in">
    <h3 id="t0" className="line1 show">
      <Typing speed={textShowSpeed} className={"classname"} hideCursor={true} onStartedTyping={AddClasstoBody}>
        Hello.
        <Typing.Backspace speed={textDeleteSpeed} count={6} delay={textInitTime} />
        I’m
        <Typing.Backspace speed={textDeleteSpeed} count={3} delay={textPageTime} />
        I’m a
      </Typing>
    </h3>
    <nav id="menu">

      <span title="" id="t1" className="line2 title1 show">
        <Typing speed={textShowSpeed} className={"classname"} hideCursor={true}>
          <Typing.Delay ms={textInitTime + 250} />
          Alexson
          <Typing.Backspace speed={textDeleteSpeed} count={7} delay={textPageTime + 100} />
          <span className="dd">/develop</span><span className="dt">er</span>
        </Typing>
      </span>

      <span title="" id="t2" className="line2 title2 show">
        <Typing speed={textShowSpeed} className={"classname"} hideCursor={true}>
          <Typing.Delay ms={textInitTime + 500} />
          from
          <Typing.Backspace speed={textDeleteSpeed} count={4} delay={textPageTime + 200} />
          <span className="dd">/design</span><span className="dt">er</span>
        </Typing>
      </span>

      <span title="" id="t3" className="line2 title3 show">
        <Typing speed={textShowSpeed * 2} className={"classname"} hideCursor={true}>
          <Typing.Delay ms={textInitTime + 750} />
          Hong Kong
          <Typing.Backspace speed={textDeleteSpeed} count={9} delay={textPageTime + 100} />
          <span className="dd">/art</span><span className="dt">ist</span>
        </Typing>
      </span>

      <span title="" id="t4" className="line2 title4 show">
        <Typing speed={textShowSpeed} className={"classname"} hideCursor={true} onAfterType={RemoveClasstoBody} >
          <Typing.Delay ms={textInitTime + 1000 + textPageTime + 550} />
          {/* <Typing.Backspace speed={textDeleteSpeed} count={1} delay={textPageTime + 350} /> */}
          <span className="dd">/produce</span><span className="dt">r</span>
        </Typing>
      </span>

      {/* <span title="" id="t1" className="line2 title1 show">developer</span>
      <span title="" id="t2" className="line2 title1 show">/designer</span>
      <span title="" id="t3" className="line2 title1 show">/artist</span>
      <span title="" id="t4" className="line2 title1 show">/producer</span> */}


    </nav>
    {/* <span className="icodown"></span> */}
  </div>

);

const AddClasstoBody = () => {
  // console.log("hidden add");
  window.scrollTo(0, 0);
  document.body.classList.add("hidden");
}

const RemoveClasstoBody = () => {
  // console.log("hidden remove");
  document.body.classList.remove("hidden");
  const ullink = document.querySelectorAll("ul.gallerywrap");
  ullink[0].classList.add("galleryIn");
}

let textVisible = false;
let currentSection = 0;
let sectionVisible0, sectionVisible1, sectionVisible2, sectionVisible3, sectionVisible4, sectionVisibleLast, sectionVisibleContact;
let linkto;




export default function Home() {

  const pathRef = React.createRef();
  const classRef = React.createRef();

  const hiddenRef0 = useRef();
  const hiddenRef1 = useRef();
  const hiddenRef2 = useRef();
  const hiddenRef3 = useRef();
  const hiddenRef4 = useRef();
  const hiddenRefLast = useRef();
  const hiddenRefContact = useRef();


  //gallerywrap
  useEffect(() => {


    window.addEventListener('scroll', scrollHandler);
    document.getElementsByClassName("line1")[0].addEventListener('animationend', animationHandler);
    document.getElementById("t1").addEventListener('animationend', animationHandler2);
    document.getElementById("t2").addEventListener('animationend', animationHandler2);
    document.getElementById("t3").addEventListener('animationend', animationHandler2);
    document.getElementById("t4").addEventListener('animationend', animationHandler2);
    const alink = document.querySelectorAll("ul.gallerywrap a.dlink");
    for (let i = 0; i < alink.length; i++) {
      alink[i].addEventListener("click", function () { delayClick(alink[i].rel) });
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      document.getElementsByClassName("line1")[0].removeEventListener('animationend', animationHandler);
      document.getElementById("t1").removeEventListener('animationend', animationHandler2);
      document.getElementById("t2").removeEventListener('animationend', animationHandler2);
      document.getElementById("t3").removeEventListener('animationend', animationHandler2);
      document.getElementById("t4").removeEventListener('animationend', animationHandler2);
      // document.querySelectorAll("a.gallerylink").removeEventListener('click', delayClick);
    };
  }, []);

  const delayClick = (msg) => {
    linkto = msg
    // console.log("linkto : "+linkto)
    //add class

    var line1 = document.getElementsByClassName("line1")[0];
    var title1 = document.getElementsByClassName("title1")[0];
    var title2 = document.getElementsByClassName("title2")[0];
    var title3 = document.getElementsByClassName("title3")[0];
    var title4 = document.getElementsByClassName("title4")[0];
    const galleryUL = document.querySelectorAll("ul.gallerywrap");
    for (let i = 0; i < galleryUL.length; i++) {
      galleryUL[i].classList.remove("galleryIn");
      galleryUL[i].classList.add("galleryOut");
    }

    line1.classList.add("hide");
    line1.classList.remove("show");

    title1.classList.add("hide");
    title1.classList.remove("show");

    title2.classList.add("hide");
    title2.classList.remove("show");

    title3.classList.add("hide");
    title3.classList.remove("show");

    title4.classList.add("hide");
    title4.classList.remove("show");

    setTimeout(GoLink, 500);
  }
  function GoLink() {
    // window.location.href = linkto;
    // alert("reload");
    // console.log("reload");
    window.scrollTo(0, 0);
    window.location.reload();
  }

  const animationHandler = () => {
    const cNmae = "dHide";
    textVisible = !textVisible;
    // console.log("textVisible : " + textVisible);
    if (!textVisible && currentSection != 0) {
      // document.querySelector("#t2").classList.add(cNmae);
      // document.querySelector("#t3").classList.add(cNmae);
      // document.querySelector("#t4").classList.add(cNmae);
    }
  }

  const animationHandler2 = () => {


    const cNmae = "dHide";
    switch (currentSection) {
      case 1:
        // document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 2:
        document.querySelector("#t1").classList.add(cNmae);
        // document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 3:
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        // document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 4:
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        // document.querySelector("#t4").classList.add(cNmae);
        break;
      case 5:
        document.querySelector("#menugroup").classList.add(cNmae);
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
    }
  }

  const scrollHandler = () => {

    // const marginY = -500;
    const marginY = -(window.innerHeight * 0.25);
    sectionVisible0 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef0.current.offsetTop);
    sectionVisible1 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef1.current.offsetTop);
    sectionVisible2 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef2.current.offsetTop);
    sectionVisible3 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef3.current.offsetTop);
    sectionVisible4 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef4.current.offsetTop);
    sectionVisibleLast = (window.pageYOffset + window.innerHeight + marginY >= hiddenRefLast.current.offsetTop);
    sectionVisibleContact = (window.pageYOffset + window.innerHeight + marginY >= hiddenRefContact.current.offsetTop);
    // sectionVisibleLast = (window.pageYOffset + window.innerHeight + -50 >= hiddenRefLast.current.offsetTop);
    // sectionVisibleContact = (window.pageYOffset + window.innerHeight + -50 >= hiddenRefContact.current.offsetTop);


    if (sectionVisible0)
      currentSection = 0;
    if (sectionVisible1)
      currentSection = 1;
    if (sectionVisible2)
      currentSection = 2;
    if (sectionVisible3)
      currentSection = 3;
    if (sectionVisible4)
      currentSection = 4;
    if (sectionVisibleLast)
      currentSection = 5;
    if (sectionVisibleContact)
      currentSection = 6;

    const cNmae = "dHide";

    var menugroup = document.getElementById("menugroup");
    var line1 = document.getElementsByClassName("line1")[0];
    var title1 = document.getElementsByClassName("title1")[0];
    var title2 = document.getElementsByClassName("title2")[0];
    var title3 = document.getElementsByClassName("title3")[0];
    var title4 = document.getElementsByClassName("title4")[0];
    var wcover = document.getElementsByClassName("wcover")[0];
    if (currentSection == 0) {

      textVisible = true;
      document.querySelector("#menugroup").classList.remove(cNmae);
      document.querySelector("#t1").classList.remove(cNmae);
      document.querySelector("#t2").classList.remove(cNmae);
      document.querySelector("#t3").classList.remove(cNmae);
      document.querySelector("#t4").classList.remove(cNmae);

      menugroup.classList.add("in");
      menugroup.classList.remove("out");

      line1.classList.add("show");
      line1.classList.remove("hide");

      title1.classList.add("show");
      title1.classList.remove("hide");

      title2.classList.add("show");
      title2.classList.remove("hide");

      title3.classList.add("show");
      title3.classList.remove("hide");

      title4.classList.add("show");
      title4.classList.remove("hide");

      if (wcover.classList.contains("whide"))
        wcover.classList.remove("whide")


    } else {


      menugroup.classList.add("out");
      menugroup.classList.remove("in");

      line1.classList.add("hide");
      line1.classList.remove("show");

      const ullink = document.querySelectorAll("ul.gallerywrap");
      const gIn = "galleryIn";

      switch (currentSection) {
        case 1:
          //show
          title1.classList.add("show");
          title1.classList.remove("hide");

          //hide
          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 2:

          //show
          title2.classList.add("show");
          title2.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[1].classList.add(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 3:

          //show
          title3.classList.add("show");
          title3.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[2].classList.add(gIn);
          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 4:
          //show
          title4.classList.add("show");
          title4.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          document.querySelector("#t4").classList.remove(cNmae);


          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[3].classList.add(gIn);
          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);


          break;

        case 5:
          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          wcover.classList.add("whide")

          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
      }

    }

    const pageNumobj = document.querySelector(".current");
    pageNumobj.innerHTML = currentSection;




    // console.log("currentSection: " + currentSection);
    // console.log("sectionVisible0: " + sectionVisible0);
    // console.log("sectionVisible1: " + sectionVisible1);
    // console.log("sectionVisible2: " + sectionVisible2);
    // console.log("sectionVisible3: " + sectionVisible3);
    // console.log("sectionVisible4: " + sectionVisible4);
    // console.log("sectionVisibleLast: " + sectionVisibleLast);

  }



  return (
    <div>

      {/* <div id="previewover"><img src={previewImage} /></div> */}
      <Header href="/" className="home"></Header>
      <div id="content" className="clearfix">

        <div id="main" role="main" className="col three-quarters">
          <AnimatedTypingComponent></AnimatedTypingComponent>
        </div>

        <div className="sectionwrap">

          <div ref={hiddenRef0}>
            <section className="sectionspace"></section>
          </div>

          <div ref={hiddenRef1}>
            <GallerySlide pathRef={"listing/develop"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef2}>
            <GallerySlide pathRef={"listing/design"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef3}>
            <GallerySlide pathRef={"listing/art"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef4}>
            <GallerySlide pathRef={"listing/produce"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRefLast}>
            <section className="sectionspace aboutParent">
              <div className="sectiongroup">
                <div className="aboutWrap">
                  <AboutBody></AboutBody>

                  <a href="/about" rel="/about" title="" className="dlink clickGroup">
                    <span className="clickarrow">➜</span>
                    <div className="clicknum">Exhibits &amp; Experience</div>
                    <span className="clickline"></span>
                  </a>

                </div>
              </div>
            </section>
          </div>

          <div ref={hiddenRefContact}>
            <section className="sectionspace contactParent">
              <ContactBody></ContactBody>
            </section>
          </div>

        </div>
      </div>

      {/* {    console.log("currentSection: " + currentSection)} */}
      <Footer pageNow={currentSection} totalPage={5}></Footer>


      <div id="aframe"></div>

      <Application pageNow=""></Application>

    </div >
  )
}