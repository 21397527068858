import React from 'react'
import Typing from 'react-typing-animation'

export default function AboutBody(props) {

  const tSpeed = 5;

  return (
    <div>
      <Typing speed={tSpeed} hideCursor={true}>
        <h2>Alexson from Hong Kong</h2>
      </Typing>



      <p>Alexson Chu is a experienced Multimedia Creator for years. In the early years experience in web design and interaction design. He acquired skills in Experience Design, Interactive Design, Game Programming, Art Direction, Web design and Creative Direction.</p>

      <p>As the cross-media experience and cross-field job-duty, he is well understand every aspect of the medias and take advantage of each media to enhance overall experience.</p>



    </div>
  )
}